<template>
  <div>
    <a-card :bordered="false">
      <div>
        <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          title="审批记录"
          @back="goBack"
        />
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleWatch(record)">审批详情</a>
<!--            <a-divider type="vertical"/>-->
<!--            <a @click="handleBill(record)">关联账单</a>-->
          </template>
        </span>
      </s-table>
    </a-card>
    <approve-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import ApproveForm from '@/views/a-trade/approve/admin/ApproveForm'

import { getApproveToDoList, doApproveToDo } from '@/api/trade_design'
export default {
  name: 'TableShenPiToDoList',
  components: {
    STable,
    ApproveForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'business_type',
          width: 150,
          customRender: (text) => this.$Dictionaries.trade_approve_type[text] || '无'
        },
        {
          title: '操作人',
          dataIndex: 'user_nickname'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '结束时间',
          dataIndex: 'last_update',
          align: 'center',
          customRender: (text, record) => record.is_completed ? text : '-'
  },
        {
          title: '状态',
          dataIndex: 'result',
          align: 'center',
          customRender: (text) => this.$Dictionaries.approval_result[text] || '无'
        },
        {
          title: '是否结束',
          dataIndex: 'is_completed',
          align: 'center',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      dispatchBill: {
        'trade_sell_contract': '/trade/sell_contract/',
        'trade_contract': '/trade/contract/',
        'trade_inventory': '/trade/inventory/',
        'trade_payment': '/trade/payment/',
        'trade_fapiao': '/trade/fapiao/',
        'trade_credit_letter': '/trade/credit_letter/',
        'trade_credit_letter_repayment': '/trade/credit_letter_repayment/'
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return getApproveToDoList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
    const data_id = this.$route.params.id
    const business_type = this.$route.query.type
    this.queryParam.data_id = data_id
    this.queryParam.business_type = business_type
  },
  methods: {
    goBack () {
      console.log(this.$router)
      this.$router.go(-1)
    },
    setCurrentPage () {
      // const page = sessionStorage.getItem('RentCurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('RentCurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleCreate (record) {
      this.mdl = record
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = false
          this.visible = false
          if (this.mdl) {
            this.handleApprove(this.mdl, values)
          }
        } else {
          this.confirmLoading = false
        }
        this.mdl = {}
      })
    },
    handleApprove (item, data) {
      console.log(item, data)
      doApproveToDo(item.id, data).then((res) => {
        console.log(res, '审批结果')
        this.$refs.table.refresh()
      })
    },
    handleEdit (item) {
      console.log(item)
      this.mdl = item
      this.visible = true
      this.confirmLoading = false
    },
    handleWatch (item) {
      console.log(item)
      // sessionStorage.setItem('RentCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: '/trade/approval/todo/' + item.id + '/',
        query: {
          type: item.business_type
        }
      })
    },
    handleBill (item) {
      console.log(item)
      // sessionStorage.setItem('RentCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: this.dispatchBill[item.business_type] + item.data_id + '/'
      })
    }
  }
}
</script>
